import Vue from "vue";
import dayjs from "dayjs";
import App from "./App.vue";

declare global {
  interface Window {
    Bus: Vue;
    axios: Axios;
  }
}

/**
 * International Telephone Input
 */
 import VueTelInput from 'vue-tel-input';
 import 'vue-tel-input/dist/vue-tel-input.css';
 Vue.use(VueTelInput);

/**
 * Event bus
 */
window.Bus = new Vue();

/**
 * JSON API
 */
import jsonAPI from "./api";
window.axios = jsonAPI;

/**
 * Validation
 */
// import {
//   configure,
//   ValidationObserver,
//   ValidationProvider,
//   extend,
// } from "vee-validate";
// import { required, email } from "vee-validate/dist/rules";

// configure({
//   classes: {
//     valid: "is-valid",
//     invalid: "is-invalid",
//     dirty: ["is-dirty", "is-dirty"],
//   },
// });

// Vue.component("ValidationObserver", ValidationObserver);
// Vue.component("ValidationProvider", ValidationProvider);

// extend("required", {
//   ...required,
//   message: "{_field_} is required",
// });

// extend("email", {
//   ...email,
//   message: "Email is invalid",
// });

/**
 * Validation
 */
import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
  events: "change|blur",
});

/**
 * VTooltip
 */
import { VTooltip } from "v-tooltip";
Vue.directive("tooltip", VTooltip);

/**
 * Datepicker
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueFlatPickr from "vue-flatpickr-component";
Vue.use(VueFlatPickr);

/**
 * Laravel pagination
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Pagination from "laravel-vue-pagination";
import { Axios } from "axios";
Vue.component("Pagination", Pagination);

/**
 * Filters
 */
Vue.filter("formatDate", function(r: string) {
  if (r) return dayjs(r).format("DD-MM-YYYY");
});
Vue.filter("formatDateHours", function(r: string) {
  if (r) return dayjs(r).format("DD-MM-YYYY H:mm");
});
Vue.filter("formatLocaleDateString", function(r: string) {
  if (r)
    return new Date(r).toLocaleDateString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      // weekday: 'long',
      year: "numeric",
      month: "long",
      day: "numeric",
    });
});
Vue.filter("round", function(r: number) {
  return r || (r = 0), (r = Math.round(r * Math.pow(10, 2)) / Math.pow(10, 2));
});
Vue.filter("toFixed", function(r: number) {
  return r || (r = 0), (r = parseFloat((Math.round(r * 100) / 100).toFixed(2)));
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

/**
 * Vuex
 */
import store from "./store";
/**
 * Router
 */
import Router from "./router";

new Vue({
  render: (h) => h(App),
  router: Router,
  store,
}).$mount("#app");

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import jsonAPI from "../../api";

interface Status {
  index: number;
  key: string;
  label: string;
}

interface StatusConfigurationObject {
  default: boolean;
  name: string;
  status: Status[];
}

interface ConfigurationData {
  id: string;
  organization_id: string;
  group: string;
  owner_id: string;
  role: string;
  data: StatusConfigurationObject;
  created_at: Date;
  updated_at: Date;
}

interface Response {
  data: ConfigurationData[];
}

@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true
})
export default class StatusStore extends VuexModule {
  public status: Array<Status> = []

  public get getStatusList(): Array<Status> {
    return this.status;
  }

  @Action({ commit: 'setStatusList' })
  public async GET_STATUS_LIST() {
    const statusObj = await jsonAPI.get<Response>("/configuration/status");
    const status = statusObj.data.data[0];
    return status.data.status;
  }
  @Action({ commit: 'setStatusList' })
  public SET_STATUS_LIST(statusList: Array<Status>) {
    return statusList;
  }
  @Action({ commit: 'updateStatus' })
  public UPDATE_STATUS(status: Status) {
    return status;
  }

  @Mutation
  public setStatusList(statusList: Array<Status>) {
    this.status = statusList;
  }
  @Mutation
  public updateStatus(status: Status) {
    this.status.push(status);
  }
}
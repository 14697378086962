import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import jsonAPI from "../../api";
interface Tag {
  [key: string]: string;
}
@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true,
})
export default class Tags extends VuexModule {
  public tags: Array<Tag> = [];
  public otherorganizationtags: Array<Tag> = [];

  public get getTags(): Array<Tag> {
    return this.tags;
  }
  public getTag(id: string): Tag | null {
    const tag = this.tags.filter((tag) => tag.id === id);
    return tag.length > 0 ? tag[0] : null;
  }

  @Action({ commit: "setTags" })
  public SET_TAGS(tags: Array<Tag>) {
    return tags;
  }
  @Action({ commit: "setTags" })
  public async GET_TAGS() {
    let all_tags = this.tags;
    const tags = await jsonAPI.get("/setup/tag").then(({ data }) => {
      return data;
    });
    if (tags) {
      all_tags = tags as Tag[];
    }
    return all_tags;
  }
  @Action({ commit: "updateTags" })
  public UPDATE_TAGS(tags: Tag) {
    return tags;
  }

  @Mutation
  public setTags(tags: Array<Tag>) {
    this.tags = tags;
  }
  @Mutation
  public set_other_organization_tags(tags: Array<Tag>) {
    this.otherorganizationtags = tags;
  }
  @Mutation
  public updateTags(tag: Tag) {
    this.tags.push(tag);
  }
}

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

interface Payload {
  id: string;
  title: boolean;
  sender_email: string;
  sender_name: string;
  size: string;
  data: Record<string, unknown> | null;
}

@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true,
})
export default class Sidebar extends VuexModule {
  public active = false;
  public content = "";
  public title = "";
  public id = "";
  public size = "";
  public data: Record<string, unknown> | null = {};
  public config: Record<string, unknown> | null = {};
  public email = "";
  public name = "";

  public get modalConfig(): Record<string, unknown> | null {
    return this.config;
  }
  public get modalActive(): boolean {
    return this.active;
  }
  public get modalTitle(): string {
    return this.title;
  }

  @Action
  public openModal(payload: Payload) {
    this.context.commit("SHOW_MODAL", true);
    this.context.commit("SET_TITLE", payload.title);
    this.context.commit("SET_EMAIL", payload.sender_email);
    this.context.commit("SET_NAME", payload.sender_name);

    if (payload.id) {
      this.context.commit("SET_ID", payload.id);
    }

    if (payload.data) {
      this.context.commit("SET_DATA", payload.data);
    }

    if (payload.size) {
      this.context.commit("SET_SIZE", payload.size);
    }
  }
  @Action({ commit: "HIDE_MODAL" })
  public closeModal() {
    return false;
  }
  @Action({ commit: "RESET_DATA" })
  public resetModalData() {
    return;
  }
  @Action({ commit: "SET_CONFIG" })
  public setModalConfig(payload: Record<string, unknown>) {
    return payload;
  }

  @Mutation
  public SHOW_MODAL(active: boolean) {
    this.active = active;
  }
  @Mutation
  public HIDE_MODAL(active: boolean) {
    this.active = active;
  }
  @Mutation
  public SET_TITLE(title: string) {
    this.title = title;
  }
  @Mutation
  public SET_EMAIL(email: string) {
    this.email = email;
  }
  @Mutation
  public SET_NAME(name: string) {
    this.name = name;
  }
  @Mutation
  public SET_ID(id: string) {
    this.id = id;
  }
  @Mutation
  public SET_DATA(data: Record<string, unknown>) {
    this.data = data;
  }
  @Mutation
  public SET_SIZE(size: string) {
    this.size = size;
  }
  @Mutation
  public SET_CONFIG(config: Record<string, unknown>) {
    this.config = config;
  }
  @Mutation
  public RESET_DATA() {
    this.active = false;
    this.content = "";
    this.title = "";
    this.id = "";
    this.data = null;
  }
}

import Vue from "vue";
import VueRouter from "vue-router";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import store from "@/store";

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  const loggedIn = store.getters.getToken;
  if (!loggedIn) {
    next();
    return;
  }
  next("/");
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "js/login" */ "@/Page/Auth/login.vue"),
      beforeEnter: ifNotAuthenticated,
      meta: {
        auth: false,
        title: "Login",
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(
          /* webpackChunkName: "js/register" */ "@/Page/Auth/register.vue"
        ),
      beforeEnter: ifNotAuthenticated,
      meta: {
        auth: false,
        title: "Register account",
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/reset-password",
      name: "password.reset",
      component: () =>
        import(
          /* webpackChunkName: "js/passwordreset" */ "@/Page/Auth/passwordreset.vue"
        ),
      beforeEnter: ifNotAuthenticated,
      meta: {
        auth: false,
        title: "Reset password",
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/unsubscribe/:organization/:email",
      name: "unsubscribe",
      component: () =>
        import(
          /* webpackChunkName: "js/Unsubscribe" */ "@/Page/Auth/unsubscribe.vue"
        ),
      beforeEnter: ifNotAuthenticated,
      meta: {
        auth: false,
        title: "Unsubscribe",
      },
    },
    {
      path: "/change-password/:token",
      name: "password.change",
      component: () =>
        import(
          /* webpackChunkName: "js/ChangePassword" */ "@/Page/Auth/password.vue"
        ),
      beforeEnter: ifNotAuthenticated,
      meta: {
        auth: false,
        title: "Change password",
      },
    },
    {
      path: "/invite/accept/:token",
      name: "invite.accept",
      component: () =>
        import(/* webpackChunkName: "js/Invite" */ "@/Page/Auth/invite.vue"),
      meta: {
        auth: false,
        title: "You have been invited to try AutomationApp",
      },
    },
    {
      path: "/account/settings",
      name: "account.settings",
      component: () =>
        import(
          /* webpackChunkName: "js/AccountSettings" */ "@/Page/Account/Settings.vue"
        ),
      meta: {
        auth: true,
        title: "Account settings",
        breadcrumb: [{ name: "Account" }],
      },
    },
    {
      path: "/account/deactivate",
      name: "account.deactivate",
      component: () =>
        import(
          /* webpackChunkName: "js/AccountDeactivate" */ "@/Page/Account/Deactivate.vue"
        ),
      meta: {
        auth: true,
        title: "Deactivate account",
        breadcrumb: [
          { name: "Account", link: "account.settings" },
          { name: "Deactivate account" },
        ],
      },
    },
    {
      path: "/",
      name: "overview",
      component: () =>
        import(/* webpackChunkName: "js/overview" */ "@/Page/overview.vue"),
      meta: {
        auth: true,
        title: "Overview",
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/onboarding",
      name: "organization.onboarding",
      component: () =>
        import(
          /* webpackChunkName: "js/Onboarding" */ "@/Page/Organization/Onboarding.vue"
        ),
      meta: {
        auth: true,
        title: "Onboarding",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/questionnaire",
      name: "organization.questionnaire",
      component: () =>
        import(
          /* webpackChunkName: "js/Questionnaire" */ "@/Page/Organization/Questionnaire.vue"
        ),
      meta: {
        auth: true,
        title: "Questionnaire",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/configure-emailing",
      name: "organization.configure-emailing",
      component: () =>
        import(
          /* webpackChunkName: "js/Onboarding" */ "@/Page/Organization/ConfigureEmailing.vue"
        ),
      meta: {
        auth: true,
        title: "Configure Emailing",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/organization",
      name: "organization",
      component: () =>
        import(
          /* webpackChunkName: "js/Organization" */ "@/Page/Organization/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Organization",
        breadcrumb: [{ name: "Organization" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/create",
      name: "organization.create",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationCreate" */ "@/Page/Organization/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Create organization",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/organization/stores",
      name: "organization.stores",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationStores" */ "@/Page/Organization/Stores.vue"
        ),
      meta: {
        auth: true,
        title: "Organization stores",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/organization/users",
      name: "organization.users",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationUsers" */ "@/Page/Organization/Users.vue"
        ),
      meta: {
        auth: true,
        title: "Organization users",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/organization/roles",
      name: "organization.roles",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationRoles" */ "@/Page/Permissions/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Organization roles",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/organization/roles/:roleId",
      name: "organization.role",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationRolePermissions" */ "@/Page/Permissions/RolePermissions.vue"
        ),
      meta: {
        auth: true,
        title: "Organization role",
        breadcrumb: [{ name: "Organization" }],
      },
    },
    {
      path: "/config",
      name: "organization.configuration",
      component: () =>
        import(
          /* webpackChunkName: "js/ConfigurationList" */ "@/Page/Configuration/Index.vue"
        ),
      meta: {
        auth: true,
        title: "View configuration options",
        breadcrumb: [{ name: "View configurations" }],
      },
    },
    {
      path: "/organization/email-template",
      name: "organization.email.template",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationTemplate" */ "@/Page/Organization/Mail/MailTemplate.vue"
        ),
      meta: {
        auth: true,
        title: "Email template setup",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Email template" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/email",
      name: "organization.email",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationMail" */ "@/Page/Organization/Mail/MailConfig.vue"
        ),
      meta: {
        auth: true,
        title: "Email configuration",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Email" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/display",
      name: "organization.display.index",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationDisplayList" */ "@/Page/Organization/DisplayTemplateIndex.vue"
        ),
      meta: {
        auth: true,
        title: "Display template",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Display template" },
        ],
      },
    },
    {
      path: "/organization/display/:id",
      name: "organization.display.template",
      component: () =>
        import(
          /* webpackChunkName: "js/OrganizationDisplay" */ "@/Page/Organization/DisplayTemplate.vue"
        ),
      meta: {
        auth: true,
        title: "Display template",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Display template" },
        ],
      },
    },
    {
      path: "/organization/integrations",
      name: "organization.integrations.index",
      component: () =>
        import(
          /* webpackChunkName: "js/Integrations" */ "@/Page/Organization/Integrations/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Integrations",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Integrations" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/integrations/:key",
      name: "organization.integrations.setup",
      component: () =>
        import(
          /* webpackChunkName: "js/IntegrationsSetup" */ "@/Page/Organization/Integrations/Setup.vue"
        ),
      meta: {
        auth: true,
        title: "Integration setup",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Integrations", link: "organization.configuration" },
          { name: "Setup" },
        ],
      },
    },
    {
      path: "/organization/tracking",
      name: "organization.tracking.index",
      component: () =>
        import(
          /* webpackChunkName: "js/ReferrerTracking" */ "@/Page/Organization/ReferrerTracking/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Tracking",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Tracking" },
        ],
      },
    },
    {
      path: "/organization/bulk-import",
      name: "organization.bulkimport.index",
      component: () =>
        import(
          /* webpackChunkName: "js/BulkImportPlugin" */ "@/Page/Organization/BulkImportPlugin/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Automation.app Bulk Import Plugin",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Sync all orders plugin" },
        ],
      },
    },
    {
      path: "/organization/segments",
      name: "organization.segment.index",
      component: () =>
        import(
          /* webpackChunkName: "js/Segments" */ "@/Page/Segment/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Filters",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Filters" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/segments/create",
      name: "organization.segment.create",
      component: () =>
        import(
          /* webpackChunkName: "js/SegmentsCreate" */ "@/Page/Segment/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Create a segment",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Filters", link: "organization.segment.index" },
          { name: "Create filter" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/segments/:id",
      name: "organization.segment.edit",
      component: () =>
        import(
          /* webpackChunkName: "js/SegmentsEdit" */ "@/Page/Segment/Edit.vue"
        ),
      meta: {
        auth: true,
        title: "Edit segment",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Filters", link: "organization.segment.index" },
          { name: "Edit filter" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/subscription",
      name: "subscription",
      component: () =>
        import(
          /* webpackChunkName: "js/Subscription" */ "@/Page/Organization/Subscription/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Subscription settings",
        breadcrumb: [{ name: "Subscription" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/subscription/create",
      name: "organization.subscription.create",
      component: () =>
        import(
          /* webpackChunkName: "js/SubscriptionCreate" */ "@/Page/Organization/Subscription/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Choose plan",
        breadcrumb: [
          { name: "Organization", link: "organization" },
          { name: "Subscribe" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/subscription/update",
      name: "organization.subscription.update",
      component: () =>
        import(
          /* webpackChunkName: "js/SubscriptionUpdate" */ "@/Page/Organization/Subscription/Update.vue"
        ),
      meta: {
        auth: true,
        title: "Update subscription",
        breadcrumb: [
          { name: "Organization", link: "organization" },
          { name: "Payment method" },
        ],
      },
    },
    {
      path: "/organization/subscription/history",
      name: "organization.subscription.history",
      component: () =>
        import(
          /* webpackChunkName: "js/SubscriptionHistory" */ "@/Page/Organization/Subscription/History.vue"
        ),
      meta: {
        auth: true,
        title: "Payment history",
        breadcrumb: [
          { name: "Organization", link: "organization" },
          { name: "Payment history" },
        ],
      },
    },
    {
      path: "/automations",
      name: "automations.index",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationActivity" */ "@/Page/Automation/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Automations",
        breadcrumb: [
          { name: "Automations" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/automations/activity",
      name: "automations.activity",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationActivity" */ "@/Page/Automation/Activity.vue"
        ),
      meta: {
        auth: true,
        title: "Automations activity",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Activity" },
        ],
      },
    },
    {
      path: "/automations/create",
      name: "automations.create",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationCreate" */ "@/Page/Automation/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Create an automations",
        breadcrumb: [
          { name: "Automations", link: "automations.index" },
          { name: "New" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/automations/edit/:id",
      name: "automations.edit",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationEdit" */ "@/Page/Automation/Edit.vue"
        ),
      meta: {
        auth: true,
        title: "Edit automations",
        breadcrumb: [
          { name: "Automations", link: "automations.index" },
          { name: "Edit" },
        ],
      },
    },
    {
      path: "/automations/copy/:id",
      name: "automations.copy",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationCopy" */ "@/Page/Automation/Copy.vue"
        ),
      meta: {
        auth: true,
        title: "Copy automations",
        breadcrumb: [
          { name: "Automations", link: "automations.index" },
          { name: "Copy" },
        ],
      },
    },
    {
      path: "/recipes",
      name: "recipes.index",
      component: () =>
        import(/* webpackChunkName: "js/Recipe" */ "@/Page/Recipe/Index.vue"),
      meta: {
        auth: true,
        title: "Install recipes",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Install recipe" },
        ],
      },
    },
    {
      path: "/automations/install-recipe",
      name: "automations.recipe",
      component: () =>
        import(
          /* webpackChunkName: "js/AutomationInstallRecipe" */ "@/Page/Automation/Recipe.vue"
        ),
      meta: {
        auth: true,
        title: "Install automations recipe",
        breadcrumb: [
          { name: "Automations", link: "automations.index" },
          { name: "Install recipe" },
        ],
      },
    },
    {
      path: "/orders",
      name: "order.index",
      component: () =>
        import(/* webpackChunkName: "js/Orders" */ "@/Page/Order/Index.vue"),
      meta: {
        auth: true,
        title: "Orders",
        breadcrumb: [{ name: "Orders" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/order/view-create",
      name: "order.view.create",
      component: () =>
        import(
          /* webpackChunkName: "js/OrderViewCreate" */ "@/Page/Order/CreateView.vue"
        ),
      meta: {
        auth: true,
        title: "Order view create",
        breadcrumb: [{ name: "Orders" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/order/view/:id",
      name: "order.view",
      component: () =>
        import(/* webpackChunkName: "js/OrderView" */ "@/Page/Order/View.vue"),
      meta: {
        auth: true,
        title: "Order list",
        breadcrumb: [{ name: "Orders" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/order/:id",
      name: "order.single",
      component: () =>
        import(/* webpackChunkName: "js/Order" */ "@/Page/Order/Single.vue"),
      meta: {
        auth: true,
        title: "View order",
        breadcrumb: [{ name: "Orders", link: "order.index" }, { name: "View" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/order/:id/edit",
      name: "order.edit",
      component: () =>
        import(/* webpackChunkName: "js/OrderEdit" */ "@/Page/Order/Edit.vue"),
      meta: {
        auth: true,
        title: "Edit order",
        breadcrumb: [
          { name: "Orders", link: "order.index" },
          { name: "Edit order" },
        ],
      },
    },
    {
      path: "/order/:id/invoice",
      name: "order.invoice",
      component: () =>
        import(
          /* webpackChunkName: "js/OrderInvoice" */ "@/Page/Order/Invoice.vue"
        ),
      meta: {
        auth: true,
        title: "Invoice",
        breadcrumb: [
          { name: "Orders", link: "order.index" },
          { name: "View" },
          { name: "Invoice" },
        ],
      },
    },
    {
      path: "/order/:id/display/:did",
      name: "order.display",
      component: () =>
        import(
          /* webpackChunkName: "js/OrderDisplay" */ "@/Page/Order/Display.vue"
        ),
      meta: {
        auth: true,
        title: "Display",
        breadcrumb: [
          { name: "Orders", link: "order.index" },
          { name: "View" },
          { name: "Display" },
        ],
      },
    },
    {
      path: "/contacts",
      name: "contact.index",
      component: () =>
        import(
          /* webpackChunkName: "js/ContactList" */ "@/Page/Customer/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Contacts",
        breadcrumb: [{ name: "Contacts" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/contacts/view-create",
      name: "contact.view.create",
      component: () =>
        import(
          /* webpackChunkName: "js/ContactViewCreate" */ "@/Page/Customer/CreateView.vue"
        ),
      meta: {
        auth: true,
        title: "Contact view create",
        breadcrumb: [{ name: "Contacts" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/contacts/view/:id",
      name: "contact.view",
      component: () =>
        import(
          /* webpackChunkName: "js/ContactView" */ "@/Page/Customer/View.vue"
        ),
      meta: {
        auth: true,
        title: "Contacts",
        breadcrumb: [{ name: "Contacts" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/contact/:id",
      name: "contact.single",
      component: () =>
        import(
          /* webpackChunkName: "js/ContactView" */ "@/Page/Customer/Single.vue"
        ),
      meta: {
        auth: true,
        title: "View contact",
        breadcrumb: [
          { name: "Contacts", link: "contact.index" },
          { name: "View" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/contacts/message",
      name: "contact.message",
      component: () =>
        import(
          /* webpackChunkName: "js/ContactMessages" */ "@/Page/Customer/MessageCustomer.vue"
        ),
      meta: {
        auth: true,
        title: "Send Message",
        breadcrumb: [{ name: "Send Message" }],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/config/forms",
      name: "config.forms",
      component: () =>
        import(
          /* webpackChunkName: "js/FieldGroupConfigList" */ "@/Page/FieldGroupConfig/FieldGroupConfigList.vue"
        ),
      meta: {
        auth: true,
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Questionnaire" },
        ],
      },
    },
    {
      path: "/config/forms/create",
      name: "config.forms.create",
      component: () =>
        import(
          /* webpackChunkName: "js/FieldGroupConfigCreate" */ "@/Page/FieldGroupConfig/FieldGroupConfigCreate.vue"
        ),
      meta: {
        auth: true,
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Create questionnaire" },
        ],
      },
    },
    {
      path: "/config/forms/:id",
      name: "config.forms.edit",
      component: () =>
        import(
          /* webpackChunkName: "js/FieldGroupConfigEdit" */ "@/Page/FieldGroupConfig/FieldGroupConfigEdit.vue"
        ),
      meta: {
        auth: true,
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Edit questionnaire" },
        ],
      },
    },
    {
      path: "/forms",
      name: "form.list",
      component: () =>
        import(/* webpackChunkName: "js/FormList" */ "@/Page/Forms/Index.vue"),
      meta: {
        auth: true,
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Questionnaires" },
        ],
      },
    },
    {
      path: "/form/replies/:id",
      name: "form.reply",
      component: () =>
        import(
          /* webpackChunkName: "js/ReplyList" */ "@/Page/Forms/ReplyList.vue"
        ),
      meta: {
        auth: true,
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Questionnaire replies" },
        ],
      },
    },
    {
      path: "/meta-fields",
      name: "meta.fields.index",
      component: () =>
        import(
          /* webpackChunkName: "js/metaFields" */ "@/Page/FieldCollections/Index.vue"
        ),
      meta: {
        auth: true,
        title: "Meta fields",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Meta fields" }
        ],
      },
    },
    {
      path: "/meta-fields/:id",
      name: "meta.fields.edit",
      component: () =>
        import(
          /* webpackChunkName: "js/metaFieldEdit" */ "@/Page/FieldCollections/Edit.vue"
        ),
      meta: {
        auth: true,
        title: "Meta field Collection edit",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Meta fields", link: "meta.fields.index" },
          { name: "Edit Meta field Collection" },
        ],
      },
    },
    {
      path: "/organization/view",
      name: "configuration.view",
      component: () =>
        import(/* webpackChunkName: "js/ViewList" */ "@/Page/View/Index.vue"),
      meta: {
        auth: true,
        title: "View",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Views" }
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/view/create",
      name: "configuration.view.create",
      component: () =>
        import(
          /* webpackChunkName: "js/ViewCreate" */ "@/Page/View/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Create view",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "view" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/view/edit/:id",
      name: "configuration.view.edit",
      component: () =>
        import(/* webpackChunkName: "js/ViewEdit" */ "@/Page/View/Edit.vue"),
      meta: {
        auth: true,
        title: "Edit view",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "view" },
        ],
      },
    },
    {
      path: "/organization/tags",
      name: "organization.tags",
      component: () =>
        import(/* webpackChunkName: "js/Tags" */ "@/Page/Tags/Index.vue"),
      meta: {
        auth: true,
        title: "Tags configuration",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Tags" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/organization/status",
      name: "organization.status",
      component: () =>
        import(/* webpackChunkName: "js/Status" */ "@/Page/Status/Index.vue"),
      meta: {
        auth: true,
        title: "Status configuration",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Statuses" },
        ],
        metaTags: [
          {
            name: "description",
            content: "Automate your E-commerce store - AutomationApp",
          },
        ],
      },
    },
    {
      path: "/products",
      name: "order.products",
      component: () =>
        import(
          /* webpackChunkName: "js/Products" */ "@/Page/Product/Index.vue"
        ),
      meta: {
        auth: true,
        title: "View products",
        breadcrumb: [{ name: "Orders", link: "order.index" }, { name: "List" }],
      },
    },
    {
      path: "/exports",
      name: "export.index",
      component: () =>
        import(/* webpackChunkName: "js/Exports" */ "@/Page/Exports/Index.vue"),
      meta: {
        auth: true,
        title: "Exports",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Exports" }
        ],
      },
    },
    {
      path: "/exports/create",
      name: "export.create",
      component: () =>
        import(
          /* webpackChunkName: "js/ExportsCreate" */ "@/Page/Exports/Create.vue"
        ),
      meta: {
        auth: true,
        title: "Create an export",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Exports", link: "export.index" },
          { name: "Create export" },
        ],
      },
    },
    {
      path: "/exports/:id",
      name: "export.edit",
      component: () =>
        import(
          /* webpackChunkName: "js/ExportsEdit" */ "@/Page/Exports/Edit.vue"
        ),
      meta: {
        auth: true,
        title: "Edit export",
        breadcrumb: [
          { name: "Configuration", link: "organization.configuration" },
          { name: "Exports", link: "export.index" },
          { name: "Edit export" },
        ],
      },
    },
    {
      path: "/admin/crm",
      name: "admin.crm.index",
      component: () =>
        import(/* webpackChunkName: "js/AdminCrm" */ "@/Page/Admin/Index.vue"),
      meta: {
        auth: true,
        title: "Crm Admin",
      },
    },
    {
      path: "/admin/crm/:id",
      name: "admin.crm.single",
      component: () =>
        import(/* webpackChunkName: "js/AdminCrm" */ "@/Page/Admin/Single.vue"),
      meta: {
        auth: true,
        title: "Crm Admin Customer",
        breadcrumb: [
          { name: "CRM", link: "admin.crm.index" },
          { name: "Customer" },
        ],
      },
    },
    {
      path: "*",
      beforeEnter: (to, from, next) => {
        next({ name: "login" });
      },
    },
  ],
});

Vue.use(VueRouter);

router.beforeEach((to, from, next) => {
  // Redirect to login if the user is not authenticated.
  const loggedIn = store.getters.getToken;
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }

  // Set page title.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} | Automation.app`;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  );

  // Hubspot integration.
  // trackPage(to.path)

  next();
});

// Add debugging.
Sentry.init({
  Vue,
  dsn:
    "https://df7a014747eb4bd3bb73e7e6e53c5062@o1062491.ingest.sentry.io/6052897",
  environment: process.env.ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "automation.app", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export default router;

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

interface Recipe {
  name?: string;
}

@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true,
})
export default class Automations extends VuexModule {
  public recipe: Recipe | null = {};

  get getRecipe(): Recipe | null {
    return this.recipe;
  }

  @Action
  public SET_RECIPE_TO_INSTALL(value: Recipe): void {
    this.context.commit("set_recipe_to_install", value);
  }

  @Mutation
  public set_recipe_to_install(recipe: Recipe): void {
    this.recipe = recipe;
  }
}

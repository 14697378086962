







import { Component, Vue } from "vue-property-decorator";
import BaseFlash from "@/components/UI/BaseFlash.vue";

@Component({
  components: {
    BaseFlash,
  },
})
export default class App extends Vue {}

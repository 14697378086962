/* eslint-disable @typescript-eslint/ban-ts-comment */
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import jsonAPI from "../../api";
import { OnboardingStep, Organization } from "@/types/organization";
import { Credentials, LoginResponse, User } from "@/types/user";

@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true,
})
export default class UserStore extends VuexModule {
  public user: User | null = null;
  public accessToken = "";
  public onboarding = false;
  public organization: Organization | null = null;
  public impersonation = false;
  public organization_exists = false;
  public platform_selected = false;
  public plugin_installed = false;
  public action_exists = false;

  public get isLogged(): boolean {
    return this.user !== null;
  }
  public get getToken() {
    return this.accessToken;
  }
  public get isOnboarding(): boolean {
    return this.onboarding;
  }
  public get getUser(): User | null {
    return this.user;
  }
  public get getOrganization(): Organization | null {
    return this.organization;
  }
  public get isImpersonating(): boolean {
    return this.impersonation;
  }
  public get isActive(): boolean {
    if (
      this.organization &&
      this.organization.settings &&
      this.organization.settings.active
    ) {
      return this.organization.settings.active;
    }
    return false;
  }
  public get limitReacted(): boolean {
    if (
      this.organization &&
      this.organization.executionPoints &&
      !this.organization.onTrial
    ) {
      return (
        this.organization.executionPoints <= 0 && !this.organization.onTrial
      );
    }
    return false;
  }

  @Action({ commit: "setUser" })
  public LOGIN(user: User) {
    return user;
  }

  @Action({ commit: "activateOnboarding" })
  public ACTIVATE_ONBOARDING() {
    return true;
  }
  @Action({ commit: "activateOnboarding" })
  public DEACTIVATE_ONBOARDING() {
    return false;
  }
  @Action({ commit: "set_organization" })
  public REGISTER_ORGANIZATION(org: Organization) {
    return org;
  }
  @Action
  public RESET(credentials: Credentials) {
    return jsonAPI.post("/reset", credentials);
  }
  @Action
  public UNSUBSCRIBE(data: { organization: Organization; email: string }) {
    return jsonAPI.post(`/unsubscribe/${data.organization}`, {
      email: data.email,
    });
  }
  @Action
  public SET_PASSWORD(credentials: Credentials) {
    return jsonAPI.post("/password", credentials);
  }
  @Action({ commit: "clearUserData" })
  public LOGOUT() {
    return "";
  }
  @Action({ commit: "setToken" })
  public SET_TOKEN(token: string) {
    return token;
  }
  @Action
  public async fetchOauthUrl({
    provider,
    action,
  }: {
    provider: string;
    action: string;
  }) {
    const { data }: { data: { url: string } } = await jsonAPI.post(
      `/oauth/${provider}/${action}`
    );
    return data.url;
  }
  @Action
  public async fetchSystemConnectUrl({ provider }: { provider: string }) {
    const { data }: { data: { url: string } } = await jsonAPI.post(
      `/system/connect/${provider}`
    );
    return data.url;
  }
  @Action({ commit: "set_organization" })
  public SET_ORGANIZATION(organization: Organization) {
    return organization;
  }
  @Action({ commit: "updateOnboarding" })
  public CHECK_ONBOARDING_STEP(step_status: OnboardingStep) {
    return step_status;
  }
  @Action({ commit: "set_organization" })
  public updateOrganization(org: Organization) {
    const org_id = org.id;
    jsonAPI
      .put("/organization/" + org_id, org)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        window.Bus.$emit("flash-message", {
          text: "error main",
          type: "error",
        });
      });
  }

  @Mutation
  public setUserData(userData: LoginResponse) {
    this.user = userData.user;
    this.accessToken = userData.token;
    jsonAPI.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
    if (userData.user && userData.user.impersonation) {
      this.impersonation = true;
    }
  }
  @Mutation
  public setUser(val: User) {
    this.user = val;
  }
  @Mutation
  public setToken(token: string) {
    this.accessToken = token;
  }
  @Mutation
  public activateOnboarding(bool: boolean) {
    this.onboarding = bool;
  }
  @Mutation
  public clearUserData() {
    this.user = null;
    this.accessToken = "";
    this.organization = null;
  }
  @Mutation
  public set_organization(org: Organization) {
    this.organization = org;
    this.organization_exists = true;
    if (org.configurationSettings.length) {
      const setting = org.configurationSettings.filter((setting) => {
        return setting.key === "secret";
      });
      if (setting.length) {
        this.platform_selected = true;
      }
    }
    if (org.settings && org.settings.pluginInstalled) {
      this.plugin_installed = true;
    }
    if (org.rules.length) {
      this.action_exists = true;
    }
  }
  @Mutation
  public new_organization_data(step_status: OnboardingStep) {
    if (
      this.organization &&
      this.organization.settings &&
      this.organization.settings.onboarding
    ) {
      this.organization.settings.onboarding.push(step_status);
    }
  }
  @Mutation
  public new_onboarding(step_status: boolean) {
    if (this.organization) {
      this.organization.settings = {};
      // @ts-ignore
      this.organization.settings.onboarding = [step_status];
    }
  }
  @Mutation
  public updateOnboarding(step_status: OnboardingStep) {
    const org = this.organization;
    if (org) {
      const settings = org.settings;
      if (settings) {
        const status = settings.onboarding;
        if (status) {
          const exists = status.some(function(st) {
            return st.key === step_status.key;
          });
          if (!exists) {
            if (
              this.organization &&
              this.organization.settings &&
              this.organization.settings.onboarding
            ) {
              this.organization.settings.onboarding.push(step_status);
            }
          }
        } else {
          settings.onboarding = [];
          if (
            this.organization &&
            this.organization.settings &&
            this.organization.settings.onboarding
          ) {
            this.organization.settings.onboarding.push(step_status);
          }
        }
      } else {
        if (this.organization) {
          this.organization.settings = {};
          // @ts-ignore
          this.organization.settings.onboarding = [step_status];
        }
      }
      //this.updateOrganization(org);
    }
  }
}

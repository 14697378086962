<template>
  <div class="v-snack z-1070">
    <Transition name="v-snack-transition">
      <div v-if="message" class="v-snack__wrapper v-snack--top">
        <div class="v-snack__content">
          <div
            v-if="message.type === 'error'"
            class="v-icon bg-danger text-red-900"
          >
            <i class="fas fa-times"></i>
          </div>
          <div
            v-if="message.type === 'success'"
            class="v-icon bg-success text-green-900"
          >
            <i class="fas fa-check"></i>
          </div>
          <p class="mb-0">{{ message.text }}</p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: null,
    };
  },
  mounted() {
    let timer;
    window.Bus.$on("flash-message", (message) => {
      clearTimeout(timer);
      this.message = message;
      timer = setTimeout(() => {
        this.message = null;
      }, 5000);
    });
  },
};
</script>

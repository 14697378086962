import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

// Modules
import user from "./modules/user";
import sidebar from "./modules/sidebar";
import tags from "./modules/tags";
import automations from "./modules/automations";
import statusState from "./modules/status";
import permissions from "./modules/permissions";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user: user,
    sidebar: sidebar,
    tags: tags,
    automations: automations,
    status: statusState,
    permissions: permissions,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  strict: debug,
});

import axios, { AxiosRequestConfig } from "axios";
import NProgress from "nprogress";
import store from "@/store";

NProgress.configure({ showSpinner: false });

let token = null;
if (store.getters.accessToken) {
  token = store.getters.accessToken;
}
const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  }
}

const jsonAPI = axios.create(config);

jsonAPI.interceptors.request.use(
  (config) => {
    if (store.getters.getToken && config.headers) {
      const token = store.getters.getToken;
      config.headers.Authorization = `Bearer ${token}`
    }
    NProgress.start();
    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

jsonAPI.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    if (error.request && error.request.status === 401) {
      store.dispatch("LOGOUT");
    }
    return Promise.reject(error);
  }
);

export default jsonAPI;

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Permission } from "@/types/permission";

@Module({
  namespaced: false,
  stateFactory: true,
  preserveState: true,
})
export default class Permissions extends VuexModule {
  public permissions: Array<Permission> = [];

  public get getPermissions(): Array<Permission> {
    return this.permissions;
  }

  @Action({ commit: "setPermissions" })
  public SET_PERMISSIONS(permissions: Array<Permission>) {
    return permissions;
  }
  @Action({ commit: "updatePermissions" })
  public UPDATE_PERMISSIONS(permission: Permission) {
    return permission;
  }
  @Action
  public HAS_PERMISSION(permission: string): boolean {
    if (this.permissions.some((e) => e.slug === permission)) {
      return true;
    }
    return false;
  }

  @Mutation
  public setPermissions(permissions: Array<Permission>) {
    this.permissions = permissions;
  }
  @Mutation
  public updatePermissions(permission: Permission) {
    this.permissions.push(permission);
  }
}
